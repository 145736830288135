import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenHelper } from '@blancoservices/bc-auth-web';
import { LoggerService } from '@app/shared/services/logger.service';
import { environment } from '@env/environment';

const bcmdPartyURL = environment.BASE_URL_BCMD_PARTY_CREDS;

const toBeSignedApis = [
  environment.BASE_URL_BCOS,
  environment.BASE_URL_BAMS,
  environment.BASE_URL_BGC,
  environment.BASE_URL_BCUS,
  environment.BASE_URL_BSUR,
  environment.BASE_URL_CONNECTIONS,
  environment.GraphQlEndpoints.PartiesEndpoint.Url,
  environment.GraphQlEndpoints.ProductsEndpoint.Url,
  environment.GraphQlEndpoints.NotificationsEndpoint.Url,
  environment.GraphQlEndpoints.BsasEndpoint.Url
];
const openApis = [
  {
    url: bcmdPartyURL,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BCOS}/tenants/settings`,
    method: 'GET'
  },
  {
    url: `${environment.BASE_URL_BCOS}/users`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?invite_id`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?login`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?mfa`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?forgot`,
    method: 'POST'
  },
  {
    url: `${environment.BASE_URL_BAMS}/users?getHash`,
    method: 'POST'
  }
];

const skipTokenValidationApis = [
    {
      url: `${environment.BASE_URL_BAMS}/users/secured?refreshToken`,
      method: 'POST'
    },
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public router: Router,
              public logger: LoggerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldBeSigned =
      !openApis.some((api) => request.url.startsWith(api.url) && request.method === api.method) &&
      toBeSignedApis.some((url) => request.url.startsWith(url));

    const token = TokenHelper.getAccessToken(),
      tenantId = sessionStorage.getItem('tenant_id') || '',
      username = sessionStorage.getItem('username') || '';

    if (shouldBeSigned) {
      const shouldSkipTokenValidated = skipTokenValidationApis
        .some((api) => request.url.startsWith(api.url) && request.method === api.method);
      const validated = (shouldSkipTokenValidated || TokenHelper.isAccesTokenValid());

      if (token && validated) {
          request = request.clone({
            setHeaders: {
              Authorization: token,
              'x-api-key': tenantId
            }
          });

        return next.handle(request).pipe(catchError(this.catcherr.bind(this)));
      } else {
        if (token) {
          TokenHelper.clearLocalStorage();
          this.logger.showError(new Error('Session expired.'));
        }

        return throwError(new HttpErrorResponse({ status: 401 }))
                .pipe(catchError(this.catcherr.bind(this)));
      }
    }

    if (!(token || tenantId || username || this.hasKParam(request))) {
      return throwError(new HttpErrorResponse({
                status: 401,
                error: { errorMessage: 'You are not allowed to make this call' }
              })).pipe(catchError(this.catcherr.bind(this)));
    }
    if (openApis.some((api) => request.url.startsWith(api.url))) {
      request = request.clone({
        setHeaders: {
          Authorization: TokenHelper.getAccessToken() || '',
          'x-api-key': tenantId,
          'x-user-key': username
        }
      });

    }

    return next.handle(request).pipe(catchError(this.catcherr.bind(this)));
  }

  private hasKParam(request: HttpRequest<any>) {
    return request.params.get('k') || request.urlWithParams.includes('k=');
  }

  private catcherr(err: HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      // eslint-disable-next-line no-magic-numbers
      if (err.status === 401) {
        TokenHelper.clearLocalStorage();
        this.router.navigate(['/login']);
      }

      return throwError(err);
    }
  }
}
