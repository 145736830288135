// angular modules
import { NgModule } from '@angular/core';
import { CountriesTranslationsComponent } from './countries-translations/countries-translations.component';
import { BcCatchMessagesModule } from '../widgets/bc.catch-message/bc.catch-messages.module';
import { BcMessagesManager } from '../widgets/bc.catch-message/bc.messages-manager.service';
import { DevexpressTranslationsComponent } from './devexpress-translations/devexpress-translations.component';
import { TranslationsComponent } from './translations.component';
import {
  COUNTRIES_MESSAGES_MANAGER,
  CUSTODIAN_TRANSLATIONS_HELPER_MANAGER,
  DX_MESSAGES_MANAGER,
  LEI_STATUS_TRANSLATIONS_MANAGER
} from './translation.injectors';

@NgModule({
  imports: [
    BcCatchMessagesModule
  ],
  declarations: [
    CountriesTranslationsComponent,
    DevexpressTranslationsComponent,
    TranslationsComponent,
  ],
  exports: [
    CountriesTranslationsComponent,
    DevexpressTranslationsComponent,
    TranslationsComponent
  ],
  providers: [
    BcMessagesManager,
    [{ provide: COUNTRIES_MESSAGES_MANAGER, useClass: BcMessagesManager }],
    [{ provide: LEI_STATUS_TRANSLATIONS_MANAGER, useClass: BcMessagesManager }],
    [{ provide: DX_MESSAGES_MANAGER, useClass: BcMessagesManager }],
    [{ provide: CUSTODIAN_TRANSLATIONS_HELPER_MANAGER, useClass: BcMessagesManager }],
  ]
})

export class TranslationsModule {}
