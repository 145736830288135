import { Component, Inject, LOCALE_ID } from '@angular/core';
import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';
import { loadMessages, locale } from 'devextreme/localization';
import { DX_MESSAGES_MANAGER } from '../translation.injectors';

@Component({
  selector: 'bc-devexpress-translations',
  templateUrl: 'devexpress-translations.component.html'
})
export class DevexpressTranslationsComponent {
  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(DX_MESSAGES_MANAGER) public bcDxMessagesManager: BcMessagesManager,
  ) { }

  setupDevextremeLocale() {
    if (this.localeId === 'en-US' || !this.localeId) this.localeId = 'en-GB';

    loadMessages({ [this.localeId]: this.bcDxMessagesManager.translations });
    locale(this.localeId);
  }
}
