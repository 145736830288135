import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { SettingsService } from './settings.service';
import { GTMService } from './gtm.service';
import { TokenHelper } from '@blancoservices/bc-auth-web';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  private tenantId: string;

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private gtmService: GTMService
  ) { }

  start() {
    this.userService.userDetails$.subscribe(user => {
      // check tenant
      // if no tenant id and token is not valid we cannot start integrations
      if (!TokenHelper.isAccesTokenValid() || !user || !user.tenantId || user.tenantId === this.tenantId) return;

      this.tenantId = user.tenantId;

      this.settingsService.getIntegrationSettings(user.tenantId).subscribe((settings: any) => {
        this.startGTM(settings.gtm);
      });

    });
  }

  private startGTM(settings) {
    if (settings && settings.enabled) {
      this.gtmService.load(settings);
    }
  }
}
