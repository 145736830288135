import {
  Inject, PLATFORM_ID, RendererFactory2,
  Optional, Renderer2, ViewEncapsulation, Injectable
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GTMService {
  id: any;
  private renderer2: Renderer2;
  private w: any;
  private gtm: any;
  private settings: any;

  constructor(
    private userService: UserService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    private rendererFactory: RendererFactory2,
    @Optional() @Inject(DOCUMENT) private document: any // Document
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  load(settings) {
    this.settings = settings;
    this.loadGTM({ appId: settings.appId }, this.callback.bind(this));
  }

  private callback() {
    const usr = this.settings.user;
     if (!(usr.username || usr.name || usr.id
        || usr.email || usr.phoneNumber)) return;

    this.userService.userDetails$.subscribe((user: any) => {
      if (!user || !user.username) return;
      const dl = (<any> window).dataLayer || [];
      dl.push({
        event: 'userupdate',
        user : {
          username: user.username ? user.username : '', // Full name
          email: user.email ? user.email : '', // Email address
          firstName: user.name ? user.name.given : '',
          lastName: user.lastName ? user.name.family : '',
          id: user.id ? user.sub : '',
          phone: user.phoneNumber ? user.phoneNumber : ''

        }
      });
    });
  }

  private injectGTMScript(conf: any, afterInjectCallback: (ev: Event) => any): void {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const w = <any> window,
          dl = '&l=' + 'dataLayer';

    w['dataLayer'] = w['dataLayer'] || [];
    w['dataLayer'].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });

    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://www.googletagmanager.com/gtm.js?id=${this.id}${dl}`;

    if ((s as any).attachEvent) {
      (s as any).attachEvent('onload', afterInjectCallback);
    } else {
      s.addEventListener('load', afterInjectCallback, false);
    }

    if (this.renderer2 && this.renderer2.appendChild) {
      this.renderer2.appendChild(this.document.head, s);
    }
  }

  private loadGTM(config: any, afterLoadCallback: (ev?: Event) => any): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.id = config.appId;
    const w = <any> window;
    this.gtm = w['google_tag_manager'];

    if (this.gtm) {
      afterLoadCallback();
    } else {
      this.injectGTMScript(config, afterLoadCallback);
    }
  }
}
