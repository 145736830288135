export interface Document {
  id: string;
  fileName: string;
  number: string;
  postalCode: string;
  uri: string;
  type: string;
  typeText: string;
  name: string;
  expireAt: Date;
  createdAt: Date;
}

export enum UploadType {
  I = 'ID Card',
  P = 'Passport',
  C = 'Contract',
  T = 'CDD Certificate',
  O = 'Other'
}

export enum DocumentType {
  ID = 'I',
  Passport = 'P',
  Contract = 'C'
}

export enum DocumentIcon {
  ID = 'icon-id-card',
  Passport = 'icon-passport',
  Contract = 'icon-contract',
}
