import { Query } from '@app/core/common/graphql';
import { Tenant } from '@app/shared/models/tenant';

/**
 * Get Tenant Detail by id.
 */
export class TenantDetailQuery extends Query<Tenant> {
  /**
   * @param tenantId tenant id
   */
  constructor() {
    super(
      'tenantGet',
      ['name', 'email', 'phone'], // properties to return
      ); // variables object (parameters values)
  }
}
