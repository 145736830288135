// 3rd party libs
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// guards
import { AuthGuard } from '@app/core/guards/auth.guard';
import {
  ObligatoryMfaSetupComponent
} from '@core/components/software-mfa/obligatory-mfa-setup/obligatory-mfa-setup.component';
import { MfaSetupGuard } from '@core/guards/mfa-setup.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./core/components/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'confirm', loadChildren: () => import('./core/components/auth/confirm/confirm.module').then(m => m.ConfirmCodeModule) },
  { path: 'continue', loadChildren: () => import('./core/components/auth/continue/continue.module').then(m => m.ContinueModule) },
  {
    path: 'forgot-password',
    loadChildren: () => import('./core/components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'invite',
    loadChildren: () => import('./core/components/user-invite/user-invite.module').then(m => m.UserInviteModule)
  },
  {
    canActivate: [MfaSetupGuard],
    path: 'mfa-setup', component: ObligatoryMfaSetupComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
