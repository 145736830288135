import { InjectionToken } from '@angular/core';
import { BcMessagesManager } from '../widgets/bc.catch-message/bc.messages-manager.service';

export const COUNTRIES_MESSAGES_MANAGER = new InjectionToken<BcMessagesManager>('countries messages');
export const DX_MESSAGES_MANAGER = new InjectionToken<BcMessagesManager>('dx messages');
export const LEI_STATUS_TRANSLATIONS_MANAGER = new InjectionToken<BcMessagesManager>('lei status messages',
  { providedIn: 'root', factory: () => new BcMessagesManager() });

export const LOCALES_MANAGER = new InjectionToken<BcMessagesManager>('locales messages',
  { providedIn: 'root', factory: () => new BcMessagesManager() });

export const CUSTODIAN_TRANSLATIONS_HELPER_MANAGER = new InjectionToken<BcMessagesManager>('custodian messages',
  { providedIn: 'root', factory: () => new BcMessagesManager() });
