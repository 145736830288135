import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { TokenHelper, UsersGatewayService } from '@blancoservices/bc-auth-web';

import { LoggerService } from './logger.service';
import { UserService } from './user.service';

// environment
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {
  static savedCognitoSession: any;

  private static previousUrl = '';
  private static defaultMainPage = 'dashboard';

  constructor(
    private logger: LoggerService,
    private router: Router,
    private usersGateway: UsersGatewayService,
    private userService: UserService
  ) { }

  static setPreviousUrl(url: string): void {
    this.previousUrl = url;
  }

  static redirectUser(): string {
    let redirectUrl: string = this.previousUrl;

    if (redirectUrl.length === 0) {
      redirectUrl = this.defaultMainPage;
    }

    this.setPreviousUrl('');

    return redirectUrl;
  }

  static redirectProspects(idTokenPayload, refreshToken) {
    const groups = idTokenPayload['cognito:groups'];

      if (groups.includes('ProspectsGroup')) {
        // eslint-disable-next-line no-magic-numbers
        const oneHourLater = (new Date(Date.now() + 3600000)).toUTCString();

        document.cookie = `rt=${refreshToken}; expires=${oneHourLater}; domain=${environment.redirectTo.onboarding}`;
        document.cookie = `at=${TokenHelper.getAccessToken()}; expires=${oneHourLater}; domain=${environment.redirectTo.onboarding}`;
        document.cookie = `n=${idTokenPayload['cognito:username']}; expires=${oneHourLater}; domain=${environment.redirectTo.onboarding}`;

        fetch(`${environment.BASE_URL_BGC}/whitelabel`, {
          headers: {
            Authorization: TokenHelper.getAccessToken()
          }
        })
          .then(res => res.json())
          .catch(() => {
            localStorage.clear();
            window.location.assign(`https://prospect${environment.redirectTo.onboarding}/continue`);
          })
          .then((res: any) => {
            let prospectUrl: string = res.default['BCOS_WF'];

            // eslint-disable-next-line no-prototype-builtins
            if (res.hasOwnProperty('domains') && res.domains.length) {
              const domainSettings: any = res.domains.find((domain: any) => domain.application === 'BCOS_WF');
              if (domainSettings) prospectUrl = domainSettings.domain;
            }

            localStorage.clear();
            window.location.assign(`https://${prospectUrl}/continue`);
          });

        return true;
      }
  }

  logout(): Observable<any> {
    return this.usersGateway.globalSignOut()
      .pipe(
        catchError((response: HttpErrorResponse) => {
          this.logger.showError(response.error);

          return throwError(response);
        }), tap(() => {
          TokenHelper.clearLocalStorage();
          this.userService.clear();
          this.router.navigate(['/login']);
      }));
  }
}
