import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { WorkflowSettings } from '@shared/models/settings/workflow-settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,
  ) {  }

  getAuthSettings(tenantId: string): Observable<{ allowedMFAMethods?: Array<string>; }> {
    if (!tenantId) return of({});

    return this.http.get(`${environment.BASE_URL_BGC}/tenants/settings/${tenantId}/?type=authentication`);
  }

  getTenantName(tenantId: string): Observable<string> {
    if (!tenantId) return of('');

    return this.http.get(`${environment.BASE_URL_BGC}/tenants/getName/${tenantId}`)
      .pipe(map((res) => res ? res['message'] : 'ClientPortal'));
  }

  getIntegrationSettings(tenantId: string): Observable<any> {
    if (!tenantId) return of({});

    return this.http.get(`${environment.BASE_URL_BGC}/tenants/settings/${tenantId}/?type=integrations`);
  }

  getLocalisationSettings(tenantId: string): Observable<any> {
    if (!tenantId) return of({});

    return this.http.get(`${environment.BASE_URL_BGC}/tenants/settings/${tenantId}/?type=internationalisation`);
  }

  getWorkflowSettings(tenantId: string = sessionStorage.getItem('tenant_id')): Observable<WorkflowSettings> {
    if (!tenantId) return of({} as WorkflowSettings);

    return this.http.get<WorkflowSettings>(`${environment.BASE_URL_BGC}/tenants/settings/${tenantId}/?type=workflow`);
  }
}
