import { Injector, Injectable, ComponentRef, ViewContainerRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationModalComponent } from './predefined/confirmation/bc.modal.confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class BcModalService {
  modalOutlets: Array<ViewContainerRef>;

  openModal = new Subject<{index: number; status: boolean;}>();
  openModalEmitted$ = this.openModal.asObservable();

  constructor() {
    this.modalOutlets = [];
  }

  openModalBox<Component>(component: Type<Component>, index: number = 0): ComponentRef<Component> {
    this.modalOutlets[index].clear();

    this.openModal.next({ index: index, status: true });

    return this.modalOutlets[index].createComponent(component);
  }

  openModalBoxWithCustomInjector<Component>(component: Type<Component>, injector: Injector, index: number = 0): ComponentRef<Component> {
    this.modalOutlets[index].clear();

    const modalComponentRef = this.modalOutlets[index].createComponent(component, { injector: injector });

    this.openModal.next({ index: index, status: true });

    return modalComponentRef;
  }

  openConfirmationBox(questions: Array<string>, title?: string): ComponentRef<ConfirmationModalComponent> {
    const modalComponentRef = this.openModalBox(ConfirmationModalComponent);

    modalComponentRef.instance.title = title || '';
    modalComponentRef.instance.questions = questions;

    return modalComponentRef;
  }

  add(modalOutlet: ViewContainerRef): number {
    return this.modalOutlets.push(modalOutlet) - 1;
  }

  set(modalOutlet: ViewContainerRef, index: number = 0): void {
    this.modalOutlets[index] = modalOutlet;
  }

  close<Component>(modal: ComponentRef<Component>, index: number) {
    if (index !== undefined) {
      this.openModal.next({
        index: index,
        status: false
      });
    }

    modal.destroy();
  }

}
