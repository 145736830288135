import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'bc-modal.bc-new-modal',
  templateUrl: 'bc.modal.component.html'
})
export class BcModalComponent {
  close = new EventEmitter();
  customEvent = new EventEmitter();

  onClose(event: Event) {
    event.preventDefault();
    this.close.emit('close');
  }
}
