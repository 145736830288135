import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { ServiceBase } from '@core/services';

import { Party } from '@shared/models';
import { PartyDetailsQuery } from './queries/party';
import { PartiesQuery } from './queries/parties';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PartiesService extends ServiceBase {
  clientName: string = environment.GraphQlEndpoints.PartiesEndpoint.Name;

  constructor(apollo: Apollo, toastr: ToastrService) {
    super(apollo, toastr);
  }

  getParty(id: string): Observable<Party> {
    return this.query(new PartyDetailsQuery(id), {
      fetchPolicy: 'network-only'
    });
  }

  getParties(id: Array<string>): Observable<Array<Party>> {
    return this.query(new PartiesQuery(id), {
      fetchPolicy: 'network-only'
    });
  }
}
