import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '@app/shared/services/user.service';
import { IntegrationsService } from '@app/shared/services/integrations.service';
import { BcModalService } from '@core/widgets/bc.modal/bc.modal.service';
import { ApolloService } from '@core/services/apollo.service';

import { environment } from '@env/environment';


@Component({
  selector: 'bc-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('modalOutlet', { read: ViewContainerRef, static: true }) bcModalOutlet: ViewContainerRef;
  themeUrl: string;

  constructor(
    private userService: UserService,
    private bcModalService: BcModalService,
    private apolloService: ApolloService,
    private integrationsService: IntegrationsService,
    private route: ActivatedRoute
  ) {
    this.apolloService.configureEndpoints();
  }

  ngOnInit() {
    if (sessionStorage.getItem('tenant_id')) {
      this.userService.updateTenantId(sessionStorage.getItem('tenant_id'));
    }

    this.route.queryParams.subscribe((params: any) => {

      if (params['tenant_id']) {
        sessionStorage.setItem('tenant_id', params['tenant_id']);
        this.userService.updateTenantId(params['tenant_id']);
      }

      this.userService.userDetails$.subscribe(user => {

        const tenantId: string = (user?.username) ? user?.tenantId :
          (params['tenant_id'] || sessionStorage.getItem('tenant_id'));

        if (tenantId) {
          sessionStorage.setItem('tenant_id', tenantId);
          this.themeUrl = `${environment.THEME_URL}/${tenantId}/bcmd-web-frontend.css`;
        }
      });

    });
    this.integrationsService.start();
  }

  ngAfterViewInit() {
    this.bcModalService.set(this.bcModalOutlet);
  }
}
