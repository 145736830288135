/**
 * This module contains all the _dumb_ shared elements (pipes, directives...) that are not related the business logic.
 * All the elements in this module should be isolated and not import anything from the outside world.
 * Reactions with anything from outside should be done using @Input and @Output flows.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from '@app/shared/pipes/safe.pipe';
import { CountryPipe } from './country.pipe';
import { BreakLinesPipe } from '@app/shared/pipes/break-lines.pipe';
import { LocalesPipe } from '@app/shared/pipes/locale.pipe';
import { LeiStatusPipe } from '@app/shared/pipes/lei-status.pipe';

@NgModule({
  declarations: [
    SafePipe,
    CountryPipe,
    LeiStatusPipe,
    BreakLinesPipe,
    LocalesPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafePipe,
    CountryPipe,
    LeiStatusPipe,
    BreakLinesPipe,
    LocalesPipe,
  ]
})
export class PipeModule {}
