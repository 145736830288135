export const environment = {
  ENV: 'prd',
  BKYC: 'blanco',
  VERSION: 'v.4.85.0',
  BASE_URL: 'https://portal.blanco.cloud/',

  BASE_URL_BCOS: 'https://api.blanco.cloud/bcos',
  BASE_URL_BGC: 'https://api.blanco.cloud/bgc',
  BASE_URL_BAMS: 'https://api.blanco.cloud/access',
  BASE_URL_BCUS: 'https://api.blanco.cloud/custodians',
  BASE_URL_BSUR: 'https://api.blanco.cloud/bsur/',
  BASE_URL_BCMD_PARTY_CREDS: 'https://api.blanco.cloud/bcmd-party/creds',
  BASE_URL_CONNECTIONS: 'https://api.blanco.cloud/connections',
  THEME_URL: 'https://assets.blanco.cloud/themes',

  BUGSNAG_AKEY: '401838e7297d93443b794d8828629c9b',

  redirectTo: {
    onboarding: '.blanco.cloud'
  },

  GraphQlEndpoints: {
    Default: {
      Name: 'default',
      Url: 'https://558jx30pp9.lp.gql.zone/graphql'
    },
    // looks like this is not used, but required to compile, TODO cleanup
    SignUpEndpoint: {
      Name: 'SignUp',
      Url: 'https://6hmhcowfv4.execute-api.eu-central-1.amazonaws.com/dummy/unauthgraphql'
    },
    ProductsEndpoint: {
      Name: 'Products',
      Url: 'https://api.blanco.cloud/bcmd-product/graphql'
    },
    PartiesEndpoint: {
      Name: 'Parties',
      Url: 'https://api.blanco.cloud/bcmd-party/graphql'
    },
    NotificationsEndpoint: {
      Name: 'Notifications',
      Url: 'https://lqir2yrxcncgvilrphlgvplfmy.appsync-api.eu-central-1.amazonaws.com/graphql'
    },
    BsasEndpoint: {
      Name: 'BSAS',
      Url: 'https://api.blanco.cloud/custodians/reports/remoteGraphql'
    }
  },

  LocalCache: {
    Key: 'localCache'
  }
};
