// 3rd party libs
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { ToastNoAnimationModule, ToastrModule, ToastNoAnimation } from 'ngx-toastr';

import { BcAuthWebModule } from '@blancoservices/bc-auth-web';
// components
import { AppComponent } from './app.component';
// modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { BcBugSnagModule } from '@core/common/bugsnag/bugsnag.module';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { BcModalModule } from '@core/widgets/bc.modal/bc.modal.module';
import { PipeModule } from '@app/shared/pipes/pipe.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SoftwareMfaModule } from '@core/components/software-mfa/software-mfa.module';
import { environment } from '@env/environment';
import { ApolloModule } from 'apollo-angular';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    HttpClientModule,
    BcBugSnagModule,

    // Toastr
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
    }),

    BcAuthWebModule.forRoot(environment),
    PipeModule,
    BcModalModule,
    AppRoutingModule,
    FontAwesomeModule,
    SoftwareMfaModule,

    ApolloModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
