import { Query } from '@app/core/common/graphql';
import { Party } from '@app/shared/models/parties';

/**
 * Get Party Detail by id.
 */
export class PartyDetailsQuery extends Query<Party> {
  /**
   * @param id account id
   */
  constructor(id: string) {
    super(
      'partyGet',
      [
        'id',
        'status',
        'partyType',
        'updatedBy',
        'region',
        'locale',
        `legalActivity {
          classification
          code
          text
          userDefined
         }`,
        `naturalPerson {
          partyId,
          email,
          initials,
          insiderCompanyName,
          countryOfBirth,
          dateOfBirth,
          insider,
          preferredName,
          givenNames,
          lastName,
          nationality,
          ownShares,
          personalNumber,
          phone,
          placeOfBirth,
          sex,
          shareCompanyName,
          signer,
          ubo,
          usPerson,
          education,
          occupation,
          active,
          updatedBy,
          updatedByUserType
        }`,
        'address { addressLine1, postalCode, city, country, type }',
        `taxResidencies {
          id,
          country,
          taxIdentificationNumber,
        }`,
        `legalEntity {
          id
          businessName
          phone
          email
          chamberOfCommerceNo
          usPerson
          shareCompanyName
          legalEntityIdentifier
          legalEntityIdentifierStatus
          legalEntityIdentifierExpirationDate
          incorporationDate
          legalForm
          legalFormText
          activeLegalActivityClassification
          ownShares
          usPerson
        }`,
        `documents {
          number
          expireAt
          type
        }`
      ],
      [{ name: 'id', type: 'String!' }],
      { id }
    );
  }
}
