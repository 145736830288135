import { Query } from '@app/core/common/graphql';
import { Product } from '@app/shared/models';

/**
 * Get Party Detail by id.
 */
export class ProductDocumentsQuery extends Query<Product> {
  /**
   * @param id account id
   * @param scopeId scope id
   */
  constructor(id: string) {
    super(
      'productGet',
      [
        `id`,
        `productName`,
        `signedContract {
          name
          fileName
          uri
          name
          createdAt
        }`,
        `document {
          name
          fileName
          uri
          name
          createdAt
        }`
      ],
      [{ name: 'id', type: 'String!' }],
      { id }
    );
  }
}
