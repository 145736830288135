import { Query } from '@app/core/common/graphql';
import { Party } from '@app/shared/models/parties';

/**
 * Get Parties Detail list by ids.
 */
export class PartiesQuery extends Query<Array<Party>> {
  /**
   * @param ids party ids
   */
  constructor(ids: Array<string>) {
    super(
      'partiesGet',
      [
        'id',
        'partyType',
        'naturalPerson { preferredName, givenNames, email, phone, lastName }',
        'address { addressLine1, postalCode, city, country }',
        'legalEntity { businessName, phone, email }'
      ], // properties to return
      [{ name: 'ids', type: '[String]' }], // parameters names and types
      { ids }
    ); // variables object (parameters values)
  }
}
