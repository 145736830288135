/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BcErrorHandler } from '@app/core/common/bugsnag/bc-error-handler';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(
    private toastr: ToastrService,
    private bcErrorHandler: BcErrorHandler
  ) {  }

  log(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst' || environment.ENV === 'local') {
      // eslint-disable-next-line prefer-template
      const msg = title ? title + ': ' + message : message;
      console.log(msg);
    }
  }

  info(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst' || environment.ENV === 'local') {
      // eslint-disable-next-line prefer-template
      const msg = title ? title + ': ' + message : message;
      console.info(msg);
    }
  }

  warn(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst' || environment.ENV === 'local') {
      // eslint-disable-next-line prefer-template
      const msg = title ? title + ': ' + message : message;
      console.warn(msg);
    }
  }

  error(message: string, title?: string) {
    if (environment.ENV === 'dev' || environment.ENV === 'tst' || environment.ENV === 'local') {
      // eslint-disable-next-line prefer-template
      const msg = title ? title + ': ' + message : message;
      console.error(msg);
    }
    this.bcErrorHandler.reportHandledError(message);
  }

  showError(errorResponse: any = '') {
    const errorMessage = this.getParsedErrorMessage(errorResponse) || $localize `:@@genericError:Something went wrong. Please try again. If the problem persists, contact support`;

    if (environment.ENV === 'dev' || environment.ENV === 'tst' || environment.ENV === 'local') {
      console.log('-------------------------------------------------');
      console.error('error: ', errorResponse);
      console.log('-------------------------------------------------');
    }

    this.toastr.error(errorMessage, $localize `:@@error:Error!`);
    this.bcErrorHandler.reportHandledError(errorMessage);
  }

  showGenericError(error: any = '') {
    this.toastr.error($localize `:@@genericError:Something went wrong. Please try again. If the problem persists, contact support`, $localize `:@@error:Error!`);
    this.bcErrorHandler.reportHandledError(error);
  }

  getParsedErrorMessage(errorResponse: any = ''): string {
    // eslint-disable-next-line no-prototype-builtins
    if (errorResponse.hasOwnProperty('error')) {
      errorResponse = errorResponse.error;
    }

    if (typeof errorResponse === 'string') return errorResponse;
    // eslint-disable-next-line no-prototype-builtins
    if (errorResponse.hasOwnProperty('errorMessage')) return errorResponse.errorMessage;
    // eslint-disable-next-line no-prototype-builtins
    if (errorResponse.hasOwnProperty('message'))return errorResponse.message;

    return '';
  }
}
