<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close u-unstyled-btn">
        <svg class="svg-icon" width="20" height="20">
          <use xlink:href="#remove-icon"></use>
        </svg>
      </button>
      <h3 class="modal-title">I'm modal</h3>
    </div>

    <div class="modal-body">

    </div>

    <div class="modal-footer buttons-flex-end">
      <button class="btn bc-btn bc-btn_default bc-btn_small"
              (click)="onClose($event)" i18n="@@cancel">Cancel</button>
    </div>
  </div>
</div>
