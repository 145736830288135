import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';


import { ToastrService } from 'ngx-toastr';

import { Party, Product } from '@app/shared/models';
import { ServiceBase } from '@app/core/services';
import { PartyDocumentsGetQuery } from './queries/party-documents';

import { ProductDocumentsQuery } from './queries/products-documents';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class DocumentService extends ServiceBase {
  clientName = environment.GraphQlEndpoints.PartiesEndpoint.Name;

  constructor(
    apollo: Apollo,
    toastr: ToastrService,
    private http: HttpClient
  ) {
    super(apollo, toastr);
  }

  getPartyDocuments(ids: Array<string>): Observable<Array<Party>> {
    this.clientName = environment.GraphQlEndpoints.PartiesEndpoint.Name;

    return this.query(new PartyDocumentsGetQuery(ids), {
      fetchPolicy: 'network-only'
    });
  }

  getProductDocuments(id: string): Observable<Product> {
    this.clientName = environment.GraphQlEndpoints.ProductsEndpoint.Name;

    return this.query(new ProductDocumentsQuery(id), {
      fetchPolicy: 'network-only'
    });
  }

  getDocument(uri: string, isPdf: boolean): Observable<Blob> {
    const headers = new HttpHeaders({ Accept: isPdf ? 'application/pdf' : 'image/*' });
    const request = new HttpRequest('GET', uri, headers, { responseType: 'blob', reportProgress: false });

    return this.http.request(request).pipe(
      filter(({ type }) => type === HttpEventType.Response),
      map((res: HttpResponse<any>) => res.body)
    );
  }
}
