import { Injectable, Inject } from '@angular/core';
import { LOCALES_MANAGER } from '@app/core/translations/translation.injectors';
import { BcMessagesManager } from '@app/core/widgets/bc.catch-message/bc.messages-manager.service';

export enum LOCALES { EN_US = 'en-US', NL_NL = 'nl-NL', FR_FR = 'fr-FR', DE_DE = 'de-DE' }
export enum ADDRESS_FORMAT { NL = 'NL', INTERNATIONAL = 'international' }

export interface Locale {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocalesService {
  private _locales: Array<Locale> = [
    { value: LOCALES.EN_US, name: 'English' },
    { value: LOCALES.NL_NL, name: 'Dutch' },
    { value: LOCALES.FR_FR, name: 'French' },
    { value: LOCALES.DE_DE, name: 'German' }
  ];

  constructor(@Inject(LOCALES_MANAGER) public bcTranslationsMessagesManager: BcMessagesManager) {}

  // TODO consider to make it pre filled
  get locales() {
    return this._locales
      .map(locale => ({
        ...locale,
        name: this.bcTranslationsMessagesManager.translations[locale.value]
      }));
    }
}
