// 3rd party libs
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

// environment
import { ServiceBase } from '@app/core/services';
import { TenantDetailQuery } from '@shared/services/data-service/queries/tenant';
import { Tenant } from '@shared/models/tenant';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends ServiceBase {
  clientName: string = environment.GraphQlEndpoints.PartiesEndpoint.Name;

  constructor(apollo: Apollo, toastr: ToastrService) {
    super(apollo, toastr);
  }

  getTenantDetails(): Observable<Tenant> {
    return this.query(new TenantDetailQuery(), {
      fetchPolicy: 'cache-first'
    });
  }
}
