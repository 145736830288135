import { gql } from 'apollo-angular';
import { GraphQLOperationType } from './graphql-operation-type';

import { DocumentNode } from 'graphql';

export interface IGraphQLOperation<T> {
  variables?: any;
  extractResult(data: any): T;
}

export abstract class GraphQLOperation<T> implements IGraphQLOperation<T> {
  protected resultProperties: string;
  protected operationParams: Array<{ name: string, type: string }>;

  /**
   * @param operationName Name of the current GraphQL operation
   * @param operationType type of the operation (query, mutation etc.)
   * @param properties list of the properties to be fetched from the endpoint as result.
   * @param operationParams list of parameters this operation needs.
   * @param variables variables that will be sent with this operation.
   * @param isClientOperation If true, this is a client-side operation to be forwarded to apollo-link-state.
   */
  protected constructor(private operationName: string,
      private operationType: GraphQLOperationType,
      properties: Array<string> = [],
      operationParams: Array<{ name: string, type: string }> = [],
      public variables?,
      private isClientOperation: boolean = false) {

    // TODO: accept a string instead of an array?
    this.resultProperties = properties.join(',');
    this.filterParams(operationParams, variables);
  }

  /**
   * Get the bare operation call syntax.
   * @example `myOperation(id: 4){propertyOne, propertyTwo}`
   * @returns the bare graphql operation call.
   */
  get strippedOperationCall(): string {
    let query = this.operationName;
    if (this.operationParams.length) {
      query += `(${this.operationParams.map(prop => `${prop.name}:$${prop.name}`).join(',')})`;
    }
    if (this.isClientOperation) {
      query += ' @client';
    }
    if (this.resultProperties.length) {
      query += `{${this.resultProperties}}`;
    }

    return query;
  }

  /**
   * Get the full operation call syntax ready to be sent.
   * @example `query myOperation($id: Int!){ myOperation(id: 4){propertyOne, propertyTwo} }`
   * @returns The full oeration call ready to be sent to the graphql endpoint.
   */
  get compiledOperationCall(): DocumentNode {
    let qr = `${this.type} ${this.operationName}`;
    if (this.operationParams.length) {
      qr += `(${this.operationParams.map(prop => `$${prop.name}:${prop.type}`).join(',')})`;
    }

    return gql`${qr}{${this.strippedOperationCall}}`;
  }

  /**
   * Extract the result from the `data` object returned by the endpoint.
   * @param data the data object return by the endpoint.
   */
  extractResult(data: any): T {
    return data[this.operationName];
  }

  /**
   * Operation name.
   */
  get name(): string {
    return this.operationName;
  }

  /**
   * Operation Type.
   */
  get type(): GraphQLOperationType {
    return this.operationType;
  }

  /**
   * Operation parameters.
   * @returns the list of parameters this operation sends to the endpoint.
   */
  get params(): Array<{ name: string, type: string }> {
    return this.operationParams;
  }

  private filterParams(params: Array<{name: string, type: string}>, variables?: any): void {
    const objectProps = Object.keys(variables || {});

    this.operationParams = variables ? params.filter(param => objectProps.includes(param.name)) :
                                       params;
  }
}
