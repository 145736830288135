import { Query } from '@app/core/common/graphql';
import { Product } from '@app/shared/models/products';

/**
 * Get Party Detail by id.
 */
export class ProductDetailQuery extends Query<Product> {
  /**
   * @param id account id
   */
  constructor(id: string) {
    super(
      'productGet',
      [
        'id',
        'productType',
        'productTypeText',
        'productSubType',
        'productSubTypeText',
        'productName',
        'channel',
        'channelText',
        'status',
        'statusText',
        'investmentGoal',
        'expectedEndDate',
        'profile',
        'custodian',
        'profileName',
        'name { id, name }',
        'kycSurvey { surveyId, investmentGoal, investmentPurpose, profile, expectedEndDate }',
        'kycEndpoint { url, language }',
        'survey { id, response }',
        'bankAccount { bankAccount, accountHolderName }',
        'originOfAssets { id, originId }'
      ],
      [{ name: 'id', type: 'String!' }],
      { id }
    );
  }
}
