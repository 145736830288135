import { Query } from '@app/core/common/graphql';
import { Party } from '@app/shared/models/parties';

/**
 * Get Party Detail by id.
 */
export class PartyDocumentsGetQuery extends Query<Array<Party>> {
  /**
   * @param ids account id
   */
  constructor(ids: Array<string>) {
    super(
      'partiesGet',
      [
        'id',
        'naturalPerson { preferredName, lastName }',
        'legalEntity { businessName }',
        `documents {
          partyId
          expireAt
          fileName
          name
          uri
          type
          createdAt
        }`
      ],
      [{ name: 'ids', type: '[String]' }],
      { ids }
    );
  }
}
