export enum ProductsTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  JOINT = 'JOINT',
  BUSINESS = 'BUSINESS'
}

export enum ProductTypes {
  INDIVIDUAL = 'I',
  JOINT = 'J',
  BUSINESS = 'B'
}
