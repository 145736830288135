// angular modules
import { ErrorHandler } from '@angular/core';
import { BugsnagService } from './bugsnag.service';
import { UserService } from '@app/shared/services/user.service';

export class BcErrorHandler implements ErrorHandler {

  constructor(private bugsnagService: BugsnagService, private userService: UserService) {
    this.bugsnagService.getConfig(this.userService.userDetails$).subscribe();
   }
  reportHandledError(error) {
    this.bugsnagService.getConfig(this.userService.userDetails$).subscribe(() => this.bugsnagService.client.notify(new Error(error)));
  }

  handleError(error) {
    const chunkFailedMessage = /Loading chunk .* failed/,
    unknownUrlMessage = /Error: Cannot match any routes. URL Segment/;

    if (chunkFailedMessage.test(error.message)) {
      if (window.confirm('An error occurred while loading the page, please try again.')) {
        window.location.reload();
      }

      return;
    }
    if (unknownUrlMessage.test(error.message)) {
      this.reportHandledError(error);

      return;
    }

    this.bugsnagService.getConfig(this.userService.userDetails$).subscribe(() => this.bugsnagService.bugSnagHandler.handleError(error));
  }

}
