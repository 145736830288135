import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { ToastrModule } from 'ngx-toastr';
import { BcMessagesModule } from '@core/widgets';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { SoftwareMfaQuestionModalComponent } from './software-mfa-question/software-mfa-question-modal.component';
import { SoftwareMfaRegisterModalComponent } from './software-mfa-register/software-mfa-register-modal.component';
import { OptionalMfaSetupComponent } from './optional-mfa-setup/optional-mfa-setup.component';
import { ObligatoryMfaSetupComponent } from './obligatory-mfa-setup/obligatory-mfa-setup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    BcMessagesModule,
    SpinnerModule,
    QRCodeModule
  ],
  declarations: [
    SoftwareMfaRegisterModalComponent,
    SoftwareMfaQuestionModalComponent,
    OptionalMfaSetupComponent,
    ObligatoryMfaSetupComponent,
  ],
  exports: [
    SoftwareMfaRegisterModalComponent,
    SoftwareMfaQuestionModalComponent,
    OptionalMfaSetupComponent,
  ]
})
export class SoftwareMfaModule {}
