import { GraphQLOperation } from './graphql-operation';
import { GraphQLOperationType } from './graphql-operation-type';
import { DocumentNode } from 'graphql';

/**
 * A graphql mutation.
 */
export abstract class Mutation<T> extends GraphQLOperation<T> {
  protected constructor(operationName: string,
    properties: Array<string> = [],
    operationParams: Array<{ name: string, type: string }> = [],
    variables?: any,
    isClientOperation: boolean = false) {
    super(
      operationName,
      GraphQLOperationType.MUTATION,
      properties,
      operationParams,
      variables,
      isClientOperation
    );
  }

  /**
   * get the GraphQL mutation to be sent to the endpoint.
   */
  get mutation(): DocumentNode {
    return this.compiledOperationCall;
  }
}
