import { Component, EventEmitter } from '@angular/core';

import { BcModalComponent } from '../../bc.modal.component';

@Component({
  selector: 'bc-modal.bc-new-modal',
  templateUrl: 'bc.modal.confirmation.component.html'
})
export class ConfirmationModalComponent extends BcModalComponent {
  close = new EventEmitter();
  yes = new EventEmitter();
  no = new EventEmitter();

  title: string;
  questions: Array<string>;

  onClose(event: Event) {
    event.preventDefault();
    this.close.emit('close');
  }

  onYes(event: Event) {
    event.preventDefault();
    this.yes.emit('Yes');
  }

  onNo(event: Event) {
    event.preventDefault();
    this.no.emit('No');
  }
}
