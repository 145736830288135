import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { BcModalComponent } from './bc.modal.component';
import { ConfirmationModalComponent } from './predefined/confirmation/bc.modal.confirmation.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    BcModalComponent,
    ConfirmationModalComponent
  ],
  exports: [
    BcModalComponent,
    ConfirmationModalComponent
  ]
})
export class BcModalModule {  }
