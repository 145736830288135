<h1 class="auth-title" i18n="@@registerAuthApp">Register Authentication application</h1>
<div class="auth-container">
  <p i18n="@@downloadAuthApp">Download an Authenticator app and scan the QR code. You will receive a number in your phone. Please  enter the number below</p>

  <form (ngSubmit)="submit()" novalidate>
    <ng-container *ngIf="(qrCode$ | async) as qrCode; else: loadingBlock">

      <qrcode [qrdata]="qrCode" [width]="256" [level]="'M'"></qrcode>

      <div class="field__item">
        <label for="MFAcode" class="field__label" i18n="@@enterAuthCode">Enter authentication code</label>
        <input type="text" class="form-control" name="MFAcode" id="MFAcode" [formControl]="confirmMFACode">
        <bc-messages [element]="confirmMFACode"></bc-messages>
      </div>
    </ng-container>

    <button class="bc-btn btn bc-btn_custom bc-btn_block" i18n="@@confirm" [disabled]="confirmMFACode.invalid" type="submit">Confirm</button>
  </form>

</div>

<ng-template #loadingBlock> <bc-spinner class="content-spinner"></bc-spinner> </ng-template>

<bc-spinner *ngIf="loadingVisible" class="page-spinner"></bc-spinner>
