export interface Attribute {
  Name: string;
  Value: string;
}

export class Helpers {
  static parseAttributes(attributes: Array<Attribute>): any {
    const parsed = {};

    attributes.forEach(item => {
      parsed[item.Name] = item.Value;
    });

    return parsed;
  }

  static parseOneAttribute(attribute: Attribute): any {
    return { [attribute.Name]: attribute.Value };
  }

  static cleanObj(obj: any) {
    const keyArr: Array<any> = Object.keys(obj),
      outputArray: any = {};

    keyArr.forEach((key: any) => {
      if (obj[key]) outputArray[key] = obj[key];
    });

    return outputArray;
  }

  static replacePageWithOffset(obj: any) {
    const keyArr: Array<any> = Object.keys(obj),
      outputArray: any = {};

    keyArr.forEach((key: any) => {
      if (key === 'page') {
        outputArray.offset = this.convertPageToOffset(obj.page, obj.max);
      } else if (obj[key]) outputArray[key] = obj[key];
    });

    return outputArray;
  }

  static convertPageToOffset(page: number, max: number) {
    return (page - 1) * max;
  }

  static parseStreet(streetValue: string) {
    if (!streetValue) return ['', ''];

    const [, line1String, houseNumber] = /(.+?)\s+?(\d*\s*[\d\D\s]{0,5})$/.exec(streetValue) || [null, '', ''];

    return [houseNumber.trim(), line1String.trim()];
  }

  static getFullName(person): string {
    return person.preferredName ? `${person.preferredName} ${person.lastName || ''}` : `${person.lastName || ''}`;
  }
}
