// angular modules
import { NgModule, ErrorHandler } from '@angular/core';
import { BugsnagService } from './bugsnag.service';
import { BcErrorHandler } from './bc-error-handler';
import { UserService } from '@app/shared/services/user.service';


// create a factory which will return the bugsnag error handler
export const errorHandlerFactory = (bugsnag: BugsnagService, userService: UserService) => {
  return new BcErrorHandler(bugsnag, userService);
};

@NgModule({
  providers: [
    {
      provide: BcErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [BugsnagService, UserService]
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [BugsnagService, UserService]
    }
  ]
})
export class BcBugSnagModule { }
