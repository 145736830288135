import { NaturalPerson, TaxResidencies } from './person';
import { Document } from '@app/shared/models/document';
import { Product } from '@app/shared/models/products';
import { LegalEntity } from '@app/shared/models/legal-entity';

export enum PartyStatus {
  PENDING = 'P',
  ACCEPTED = 'A',
  ENDED = 'E'
}

export interface Party {
  legalActivity: any;
  id: string;
  partyType: string;
  updatedBy: string;
  locale: string;
  region: string;
  naturalPerson: Array<NaturalPerson>;
  address: Array<Address>;
  taxResidencies: Array<TaxResidencies>;
  documents: Array<Document>;
  partyProducts: Array<Product>;
  legalEntity: Array<LegalEntity>;
  status: PartyStatus;
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  country: string;
  houseNumber?: string;
  streetName?: string;
}

export interface NaturalPersonsInput {
  preferredName: string;
  email: string;
  lastName: string;
  phone: string;
}
