import { NgModule } from '@angular/core';
import { PipeModule } from '@app/shared/pipes/pipe.module';
import { BcBugSnagModule } from './common/bugsnag/bugsnag.module';
import { TranslationsModule } from './translations/translations.module';

@NgModule({
  imports: [
    TranslationsModule,
    PipeModule,
    BcBugSnagModule
  ],
  exports: [
    TranslationsModule
  ]
})

export class CoreModule {}
