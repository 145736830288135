import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ConnectionsService, PartiesService } from './data-service';

import { PartyConnection, NodeTypes, ConnectionNode, Party, PartyType } from '@app/shared/models';
import { UserService } from '@shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService  {
  partyConnections$: Observable<PartyConnection>;

  constructor(
    private userService: UserService,
    private connectionsService: ConnectionsService,
    private partiesService: PartiesService
  ) { }

  resolve(): Observable<PartyConnection> {
    return this.getConnections();
  }

  getConnectedParties(): Observable<Array<ConnectionNode>> {
    return this.partyConnections$.pipe(map((res: PartyConnection) => {
      const parties = res.nodes.filter((node) => node.type !== NodeTypes.PRODUCT);
      const uniqueParties = parties.filter(
        (item, index, array) => array.findIndex(a => a.id === item.id) === index
      );

      return (uniqueParties && uniqueParties.length) ? uniqueParties : [];
    }));
  }

  getConnectedPartiesIds(): Observable<Array<string>> {
    return this.getConnectedParties().pipe(map(connectedParties => connectedParties.map(x => x.id)));
  }

  getPartiesList(): Observable<Array<Party>> {
    return this.getConnectedPartiesIds().pipe(
      switchMap(ids => this.partiesService.getParties(ids)),
      map(this.getFilteredParties),
      map((collection: Array<Party>) => this.getSortedParties(collection, this.userService.user.partyId))
    );
  }

  getProducts(): Observable<Array<ConnectionNode>> {
    return this.partyConnections$.pipe(map((res: PartyConnection) =>
      res.nodes.filter((node) => node.type === NodeTypes.PRODUCT)
    ));
  }

  private getFilteredParties(parties: Array<Party>): Array<Party> {
    return parties.filter(c => c.partyType !== PartyType.NATURAL_PERSON_GROUP);
  }

  private getSortedParties(parties: Array<Party>, mainPartyId: string): Array<Party> {
    return parties.sort((x, y) => (x.id === mainPartyId ? -1 : y.id === mainPartyId ? 1 : 0));
  }

  private getConnections() {
    this.partyConnections$ = <Observable<PartyConnection>> this.connectionsService.getPartyConnections();

    return this.partyConnections$;
  }
}
