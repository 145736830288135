<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <button (click)="onClose($event)" type="button" class="close u-unstyled-btn">
        <svg class="svg-icon" width="20" height="20">
          <use xlink:href="#remove-icon"></use>
        </svg>
      </button>
      <h3 *ngIf="title" class="modal-title">{{ title }}</h3>
      <h3 *ngIf="!title" i18n="modal dialog" class="modal-title">Are you sure?</h3>
    </div>
    <div class="modal-body">
      <p *ngFor="let question of questions">{{ question }}</p>
    </div>
    <div class="modal-footer buttons-flex-end">
      <button (click)="onNo($event)" class="btn bc-btn bc-btn_default bc-btn_small" i18n="@@no">No</button>
      <button (click)="onYes($event)" class="btn bc-btn bc-btn_custom bc-btn_small" i18n="@@yes">Yes</button>
    </div>
  </div>
</div>
