// angular modules
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

// Import bugsnag-js and bugsnag-angular
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';
import Client from '@bugsnag/core/types/client';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {

  client: Client;
  bugSnagHandler: BugsnagErrorHandler;
  user: any;

  constructor() {
   }

   clearUser() {
     this.user = undefined;
   }
  getConfig(user$: Observable<any>) {
    return new Observable(observer => {
      // if we already have intialized the bugsnag session
      // just return the exixting bugsnag instance
      if (this.client && this.user && this.user.sub) {
        observer.next(this.client);
        observer.complete();

        return;
      }

      user$.subscribe((user) => {
        this.user = user?.username ? {
          id: user.sub,
          username: user.username,
          tenantId: user.tenantId
        } : null;


        // if we already have intialized the bugsnag session
        // but still no new user is present
        // just return the exixting bugsnag instance
        if (!this.user && this.client) {
          observer.next(this.client);
          observer.complete();

          return;
        }

        if (this.client) {

          this.client.setUser(this.user.id, this.user.tenantId, this.user.username);
          this.bugSnagHandler = new BugsnagErrorHandler(this.client);

          observer.next(this.client);
          observer.complete();

          return;
        }

        this.client = Bugsnag.start({
          apiKey: environment.BUGSNAG_AKEY,
          appVersion: environment.VERSION,
          releaseStage: environment.ENV,
          enabledReleaseStages: [ 'prd', 'acc', 'tst' ],
          user: this.user
        });
        this.bugSnagHandler = new BugsnagErrorHandler(this.client);
        observer.next(this.client);
        observer.complete();
      });
    });
  }
}
