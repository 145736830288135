import { GraphQLOperation, IGraphQLOperation } from './graphql-operation';
import { GraphQLOperationType } from './graphql-operation-type';
import { DocumentNode } from 'graphql';

export interface IQuery<T = any> extends IGraphQLOperation<T> {
  query: DocumentNode;
}

/**
 * A graphql query.
 */
export abstract class Query<T> extends GraphQLOperation<T> implements IQuery<T> {
  protected constructor(operationName: string,
    properties: Array<string> = [],
    operationParams: Array<{ name: string, type: string }> = [],
    variables?: any,
    isClientOperation: boolean = false) {
    super(
      operationName,
      GraphQLOperationType.QUERY,
      properties,
      operationParams,
      variables,
      isClientOperation
    );
  }

  /**
   * get the GraphQL query to be sent to the endpoint.
   */
  get query(): DocumentNode {
    return this.compiledOperationCall;
  }
}
