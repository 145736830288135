import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TokenHelper } from '@blancoservices/bc-auth-web';
import { UserService } from '@shared/services';
import { SettingsService } from '@shared/services/settings.service';
import { UserLoginService } from '@shared/services/user-login.service';

@Injectable({
  providedIn: 'root'
})
export class MfaSetupGuard  {

  constructor(
    private router: Router,
    private userService: UserService,
    private settingsService: SettingsService
  ) {  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!TokenHelper.isAccesTokenValid()) return this.redirectToLogin(state.url);

    return this.userService.resolve().then(async user => {
      if (!this.userService.isUserTenantClient()) {
        this.userService.clear();

        return this.redirectToLogin(state.url);
      }

      const allowedMFAMethods = await this.mfaMethodsSettings();

      if (!allowedMFAMethods?.length || allowedMFAMethods.includes(user.mfaMethod)) {
        this.router.navigate([UserLoginService.redirectUser()]);

        return false;
      }

      return true;
    });
  }

  redirectToLogin(url) {
    // UserLoginService.setPreviousUrl(url);
    this.router.navigate([ 'login' ]);

    return false;
  }

  mfaMethodsSettings(): Promise<Array<string>> {
    return this.settingsService.getAuthSettings(this.userService.tenantId).pipe(
      catchError((err) => {
        console.error(err);

        return of({ allowedMFAMethods: [] });
      }),
    ).toPromise().then(({ allowedMFAMethods }) => allowedMFAMethods);
  }
}
