import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
const BASE_URL_CONNECTIONS = environment.BASE_URL_CONNECTIONS;

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {
  constructor(private http: HttpClient) { }

  getPartyConnections() {
    return this.http.get(`${BASE_URL_CONNECTIONS}/graph/me`);
  }
}
