<bc-catch-messages [manager]="bcLocalesManager">
  <bc-message key="en-US" i18n="@@enUSLanguage">English</bc-message>
  <bc-message key="nl-NL" i18n="@@nlNLLanguage">Dutch</bc-message>
  <bc-message key="fr-FR" i18n="@@frFRLanguage">French</bc-message>
  <bc-message key="de-DE" i18n="@@deDELanguage">German</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="leiStatusesManager">
  <bc-message key="DUPLICATE" i18n="@@duplicate">Duplicate</bc-message>
  <bc-message key="ANNULLED" i18n="@@annulled">Annulled</bc-message>
  <bc-message key="ISSUED" i18n="@@issued">Issued</bc-message>
  <bc-message key="LAPSED" i18n="@@lapsed">Lapsed</bc-message>
  <bc-message key="MERGED" i18n="@@merged">Merged</bc-message>
  <bc-message key="PENDING_ARCHIVAL" i18n="@@pendingarchival">Pending archival</bc-message>
  <bc-message key="PENDING_TRANSFER" i18n="@@pendingtrasfer">Pending transfer</bc-message>
  <bc-message key="RETIRED" i18n="@@retired">Retired</bc-message>
</bc-catch-messages>

<bc-catch-messages [manager]="custodianTranslations">
  <bc-message key="YES" i18n="@@yes">Yes</bc-message>
  <bc-message key="NO" i18n="@@no">No</bc-message>

  <bc-message key="PREMIUM" i18n="custodian premium@@premium">Premium</bc-message>
  <bc-message key="BASIC" i18n="custodian basic@@basic">Basic</bc-message>
  <bc-message key="PLATFORM_ACCOUNT" i18n="custodian platform account@@platformaccount">Platform account</bc-message>
  <bc-message key="MANAGED_INVESTMENTS" i18n="custodian managed investments@@managedinvestments">Dimensional managed investments</bc-message>

  <bc-message key="CUSTODIAN_SENT" i18n>Sent</bc-message>

  <bc-message key="TITLE_SUCCESS" i18n="@@success">Success!</bc-message>

  <bc-message key="CUSTODIAN_BINCKBANK">BinckBank</bc-message>
  <bc-message key="CUSTODIAN_DEGIRO">DeGiro</bc-message>
  <bc-message key="CUSTODIAN_NNEK">NNEK</bc-message>
  <bc-message key="CUSTODIAN_OTHER" i18n="@@other">Other</bc-message>

  <bc-message key="CUSTODIAN_EXPORT_ALLOWED_FOR_COMPLETE_ONLY" i18n>This export is only available when registration has the status "Complete"</bc-message>

  <bc-message key="CUSTODIAN_BINCK_SERVICE_ADVISE" i18n>Advice</bc-message>
  <bc-message key="CUSTODIAN_BINCK_SERVICE_MANAGEMENT" i18n>Management</bc-message>

  <bc-message key="CUSTODIAN_BINCKBANK_STATEMENTS_ACCOUNT" i18n>Client would like to receive physical account statements</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_STATEMENTS_POSITION" i18n>Client would like to receive physical position statements</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_STATEMENTS_ANNUAL" i18n>Client would like to receive physical annual statements</bc-message>

  <bc-message key="CUSTODIAN_BINCKBANK_RISK_LOW" i18n>Low</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_RISK_MEDIUM" i18n>Medium</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_RISK_HIGH" i18n>High</bc-message>

  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_SALE_HOUSE" i18n="@@saleHouse">Sale house</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_SALE_HOUSE_2" i18n="@@saleHouse">Sale house</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_SALE_COMPANY" i18n>Sale company</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_BONUS" i18n>Employee profit sharing/bonus</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_PROFIT_OR_DIVIDENT" i18n>Profit/Divident</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_INVESTMENTS" i18n>Investments</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_INCOME_OR_PENSION" i18n>Income or pension</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_OTHER" i18n="@@other">Other</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_DIVIDEND" i18n>Dividend payments</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_HERITAGE" i18n>Heritage</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_GIFT" i18n>Gift</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_SALE_ASSETS" i18n>Sale of valuable assets (art/antique)</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_LOTTERY" i18n>Lottery gains</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_SAVINGS" i18n>Savings/investments</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_PROFIT" i18n>Company profit</bc-message>
  <bc-message key="CUSTODIAN_BINCKBANK_ORIGIN_SHARE_CAPITAL" i18n>Company share capital</bc-message>

  <bc-message key="CUSTODIAN_SEND_ERROR" i18n="@@errorSendToProcessor">Error has occurred while sending data to the processor</bc-message>
  <bc-message key="CUSTODIAN_ERROR_NOT_SIGNED" i18n>Registration not signed</bc-message>

  <bc-message key="CUSTODIAN_DEGIRO_ACC_TYPE_NOT_SELECTED" i18n>Account type is not selected for DeGiro</bc-message>
  <bc-message key="CUSTODIAN_DEGIRO_ACC_TYPE_PENSION" i18n>Pension</bc-message>

  <bc-message key="CUSTODIAN_NNEK_ORIGIN_SAVINGS" i18n>Savings</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_SALARY" i18n>Salary of current or future employment</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_INVESTMENTS" i18n>Investments </bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_GOLDEN_PARACHUTE" i18n>Golden parachute</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_SALE_COMPANY" i18n>Sale company </bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_SALE_HERITAGE" i18n>Heritage, or received in another single occasion</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_SALE_HERITAGE" i18n>Heritage, or received in another single occasion, being</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_SALE_HOUSE" i18n="@@saleHouse2">Sale house</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_OTHER" i18n>Other, being</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_OTHER" i18n="@@other">Other</bc-message>
  <bc-message key="CUSTODIAN_NNEK_ORIGIN_RESERVES" i18n>Reserves of legal entity</bc-message>

  <bc-message key="CUSTODIAN_NNEK_EDUCATION_LOWER" i18n>Lower education</bc-message>
  <bc-message key="CUSTODIAN_NNEK_EDUCATION_PROFESSIONAL" i18n>Professional education</bc-message>
  <bc-message key="CUSTODIAN_NNEK_EDUCATION_HIGHER" i18n>Higher education</bc-message>

  <bc-message key="CUSTODIAN_NNEK_JOB_CATEGORY_EMPLOYEE" i18n>Employee</bc-message>
  <bc-message key="CUSTODIAN_NNEK_JOB_CATEGORY_DIRECTOR" i18n>Director</bc-message>
  <bc-message key="CUSTODIAN_NNEK_JOB_CATEGORY_FREELANCER" i18n>Freelance</bc-message>

  <bc-message key="CUSTODIAN_NNEK_LENDER_OTHER" i18n="@@other">Other</bc-message>

  <bc-message key="CUSTODIAN_RESET_STATUS_CONFIRMATION" i18n="@@custodianResetStatusConfirmation">Are you sure you want to reset the status and remove contracts?</bc-message>
  <bc-message key="CUSTODIAN_STATUS_UPDATED" i18n>Status updated.</bc-message>


  <!--Franx channel contact-->
  <bc-message key="MARKETING_CHANNEL_PHONE" i18n="@@marketingChannelPhone">I have been contacted by telephone</bc-message>
  <bc-message key="MARKETING_CHANNEL_SEARCH_ENGINE" i18n="@@marketingChannelSearchEngine">I found Franx through a search engine</bc-message>
  <bc-message key="MARKETING_CHANNEL_NETWORK" i18n="@@marketingChannelNetwork">Via my network</bc-message>
  <bc-message key="MARKETING_CHANNEL_BANNER" i18n="@@marketingChannelBanner">I found Franx on the internet via a banner</bc-message>
  <bc-message key="MARKETING_CHANNEL_ABNAMRO" i18n="@@marketingChannelABNAMRO">I transferred from ABN Amro</bc-message>
  <bc-message key="MARKETING_CHANNEL_OTHER" i18n="@@other">Other</bc-message>

  <!--Franx user roles-->
  <bc-message key="FRANX_USER_ROLE_VIEW" i18n="@@view">View</bc-message>
  <bc-message key="FRANX_USER_ROLE_INITIATE" i18n="@@franxUserRoleInitiate">Initiate</bc-message>
  <bc-message key="FRANX_USER_ROLE_APPROVE" i18n="@@franxUserRoleApprove">Approve</bc-message>
  <bc-message key="FRANX_USER_ROLE_INITIATE_AND_APROVE" i18n="@@franxUserRoleInitiateAndApprove">Initiate & Approve</bc-message>

  <!--BinckBank origins questions-->
  <bc-message key="whatIsYourAverageIncome" i18n="@@whatIsYourAverageIncome">What is your average income?</bc-message>
  <bc-message key="whatIsOrWasYourFunction" i18n="@@whatIsOrWasYourFunction">What is or was your function?</bc-message>
  <bc-message key="whoIsOrWasYourEmployer" i18n="@@whoIsOrWasYourEmployer">Who is or was your employer?</bc-message>
  <bc-message key="whatIsTheDurationOfTheEmployment" i18n="@@whatIsTheDurationOfTheEmployment">What is the duration of the employment?</bc-message>

  <bc-message key="whatIsTheAddressOfTheSoldHouse" i18n="@@whatIsTheAddressOfTheSoldHouse">What is the address of the sold house?</bc-message>
  <bc-message key="whenWasTheHouseSold" i18n="@@whenWasTheHouseSold">When was the house sold?</bc-message>
  <bc-message key="forWhatAmountDidYouSellTheHouse" i18n="@@forWhatAmountDidYouSellTheHouse">For what amount did you sell the house?</bc-message>
  <bc-message key="whenDidYouPurchaseTheHouse" i18n="@@whenDidYouPurchaseTheHouse">When did you purchase the house?</bc-message>
  <bc-message key="forWhatAmountDidYouBuyTheHouse" i18n="@@forWhatAmountDidYouBuyTheHouse">For what amount did you buy the house?</bc-message>

  <bc-message key="whichCompanyDidYouSell" i18n="@@whichCompanyDidYouSell">Which company did you sell?</bc-message>
  <bc-message key="inWhichIndustryIsTheCompanyActive" i18n="@@inWhichIndustryIsTheCompanyActive">In which industry is the company active?</bc-message>
  <bc-message key="whenWasTheCompanySold" i18n="@@whenWasTheCompanySold">When was the company sold?</bc-message>
  <bc-message key="forHowMuchDidYouSellTheCompany" i18n="@@forHowMuchDidYouSellTheCompany">For how much did you sell the company?</bc-message>
  <bc-message key="whatIsTheAverageRevenueProfitOnAYearlyBasis" i18n="@@whatIsTheAverageRevenueProfitOnAYearlyBasis">What is the average revenue/profit on a yearly basis?</bc-message>
  <bc-message key="whatWasYourRoleWithinTheCompany" i18n="@@whatWasYourRoleWithinTheCompany">What was your role within the company?</bc-message>
  <bc-message key="howMuchOfTheCompanyDidYouOwnAtTheTimeOfSale" i18n="@@howMuchOfTheCompanyDidYouOwnAtTheTimeOfSale">How much of the company did you own at the time of sale?</bc-message>
  <bc-message key="howAndWhenDidYouObtainTheOwnershipOfTheCompany" i18n="@@howAndWhenDidYouObtainTheOwnershipOfTheCompany">How and when did you obtain the ownership of the company?</bc-message>

  <bc-message key="fromCompanyDidYouReceiveDividendsOrABonus" i18n="@@fromCompanyDidYouReceiveDividendsOrABonus">From company did you receive dividends or a bonus?</bc-message>
  <bc-message key="whatAmountDidYouReceive" i18n="@@whatAmountDidYouReceive">What amount did you receive?</bc-message>
  <bc-message key="whatIsTheAverageYearlyRevenueProfit" i18n="@@whatIsTheAverageYearlyRevenueProfit">What is the average yearly revenue/profit?</bc-message>
  <bc-message key="whatIsYourRoleWithinTheOrganization" i18n="@@whatIsYourRoleWithinTheOrganization">What is your role within the organisation?</bc-message>

  <bc-message key="fromWhoDidYouReceiveTheInheritance" i18n="@@fromWhoDidYouReceiveTheInheritance">From who did you receive the inheritance?</bc-message>
  <bc-message key="whenDidYouReceiveTheInheritance" i18n="@@whenDidYouReceiveTheInheritance">When did you receive the inheritance?</bc-message>
  <bc-message key="whatAmountDidYouInherit" i18n="@@whatAmountDidYouInherit">What amount did you inherit?</bc-message>
  <bc-message key="howDidTheTestatorObtainHisOrHerWealth" i18n="@@howDidTheTestatorObtainHisOrHerWealth">How did the testator obtain his or her wealth?</bc-message>
  <bc-message key="howManyOtherHeirsWereThereBesideYou" i18n="@@howManyOtherHeirsWereThereBesideYou">How many other heirs were there besides you?</bc-message>

  <bc-message key="fromWhoDidYouReceiveTheDonation" i18n="@@fromWhoDidYouReceiveTheDonation">From who did you receive the donation?</bc-message>
  <bc-message key="whenDidYouReceiveTheDonation" i18n="@@whenDidYouReceiveTheDonation">When did you receive the donation?</bc-message>
  <bc-message key="howDidTheDonorObtainHisOrHerWealth" i18n="@@howDidTheDonorObtainHisOrHerWealth">How did the donor obtain his or her wealth?</bc-message>
  <bc-message key="whatIsTheReasonThatYouReceivedThisDonation" i18n="@@whatIsTheReasonThatYouReceivedThisDonation">What is the reason that you received this donation?</bc-message>

  <bc-message key="whenDidYouReceiveTheGoldenHandshake" i18n="@@whenDidYouReceiveTheGoldenHandshake">When did you receive the golden handshake?</bc-message>
  <bc-message key="howMuchDidYouReceiveThroughYourGoldenHandshake" i18n="@@howMuchDidYouReceiveThroughYourGoldenHandshake">How much did you receive through your golden handshake?</bc-message>
  <bc-message key="whoWasYourEmployer" i18n="@@whoWasYourEmployer">Who was your employer?</bc-message>
  <bc-message key="whatWasYourFunction" i18n="@@whatWasYourFunction">What was your function?</bc-message>
  <bc-message key="whatWasTheDurationOfYourEmployment" i18n="@@whatWasTheDurationOfYourEmployment">What was the duration of your employment?</bc-message>
  <bc-message key="whatWasYourIncome" i18n="@@whatWasYourIncome">What was your income?</bc-message>

  <bc-message key="howDidTheDepositedAmountCameAbout" i18n="@@howDidTheDepositedAmountCameAbout">How did the (deposited) amount came about?</bc-message>
  <bc-message key="whatWasYourTotalReturnOnInvestment" i18n="@@whatWasYourTotalReturnOnInvestment">What was your total return on investment?</bc-message>
  <bc-message key="forHowLongDidYouInvest" i18n="@@forHowLongDidYouInvest">For how long did you invest?</bc-message>

  <bc-message key="fromWhichCompanyDoesTheOperatingResultOriginate" i18n="@@fromWhichCompanyDoesTheOperatingResultOriginate">From which company does the operating result originate?</bc-message>
  <bc-message key="inWhichIndustryIsTheCompanyActive" i18n="@@inWhichIndustryIsTheCompanyActive">In which industry is the company active?</bc-message>
  <bc-message key="whatWasTheAverageRevenueProfit" i18n="@@whatWasTheAverageRevenueProfit">What was the average revenue/profit?</bc-message>
  <bc-message key="whatPercentageOfOwnershipDoYouHaveInTheCompany" i18n="@@whatPercentageOfOwnershipDoYouHaveInTheCompany">What percentage of ownership do you have in the company?</bc-message>

  <bc-message key="fromWhatDidTheAmountOriginate" i18n="@@fromWhatDidTheAmountOriginate">From what did the amount originate?</bc-message>

  <bc-message key="EMPLOYEE_PRIVATE_BUSINESS" i18n="@@employeePrivateBusiness">Employee in a private business</bc-message>
  <bc-message key="SEMI_GOVERNMENTAL_INSTITUTION" i18n="@@semiGovernmentalInstitution">Working for a (semi-)governmental institution</bc-message>
  <bc-message key="PARTNER_IN_ENTERPRISE" i18n="@@partnerInEnterprise">Partner in an enterprise</bc-message>
  <bc-message key="DIRECTOR_NON_MAJOR_SHAREHOLDER" i18n="@@directorNonMajorShareholder">Director/non-major shareholder</bc-message>
  <bc-message key="SELF_EMPLOYMENT_WITHOUT_SHAREHOLDER" i18n="@@selfEmployedWithoutShareholder">Self-employed without staff/Entrepeneur/Director and major shareholder</bc-message>
  <bc-message key="STUDENT" i18n="@@student">Student</bc-message>
  <bc-message key="RETIRED" i18n="@@retired">Retired</bc-message>
  <bc-message key="UNEMPLOYED" i18n="@@unemployed">Unemployed</bc-message>
  <bc-message key="OFFERING_TRADE_IN_CRYPTO_CURRENCIES" i18n="@@offeringTradeInCryptoCurrencies">Offering trade in cryptocurrencies or Initial Coin Offering/ICO</bc-message>
  <bc-message key="PPS_OR_SIMILAR" i18n="@@PPSOrSimilar">Payment Service Provider or similar company (PSP)</bc-message>
  <bc-message key="CONSULTANCY_SERVICES" i18n="@@consultancyServices">Consultancy services</bc-message>
  <bc-message key="ENTERTAINMENT_AND_RECREATION" i18n="@@entertainmentAndRecreation">Entertainment and recreation</bc-message>
  <bc-message key="CONSTRUCTION_INFRASTRUCTURE_OFFSHORE_DREDGING_WORK" i18n="@@constructionInfrastructureOffshoreDredgingWork">Construction, infrastructure, offshore and dredging work</bc-message>
  <bc-message key="COFFEESHOP_GROWSHOP" i18n="@@coffeeshopGrowshop">Coffeeshop / growshop</bc-message>
  <bc-message key="COMMERCIAL_REAL_ESTATE_ACTIVITIES" i18n="@@commercialRealEstateActivities">(Commercial) real estate activities</bc-message>
  <bc-message key="CROWDFUNDING_PEER_TO_PEER_PLATFORMS" i18n="@@crowdfundingPeerToPeerPlatforms">Crowdfunding, peer-to-peer platforms for credit and payment services</bc-message>
  <bc-message key="MANUFACTURING_PRODUCTION" i18n="@@manufacturingProduction">Manufacturing/Production</bc-message>
  <bc-message key="PHARMACEUTICAL_INDUSTRY" i18n="@@pharmaceuticalIndustry">Pharmaceutical Industry</bc-message>
  <bc-message key="FINANCIAL_AND_INSURANCE_ACTIVITIES" i18n="@@financialAndInsuranceActivities">Financial and insurance activities</bc-message>
  <bc-message key="CHARITIES_RELIGIOUS_ORGANIZATIONS" i18n="@@charitiesReligiousOrganizations">Charities / religious organizations</bc-message>
  <bc-message key="RAW_MATERIALS_MINERALS_MINING_QUARRYING" i18n="@@rawMaterialsMineralsMiningQuarrying">Raw materials, minerals, mining and quarrying</bc-message>
  <bc-message key="CRYPTO_CURRENCIES_TRADING" i18n="@@cryptocurrenciesTrading">Cryptocurrencies Trading</bc-message>
  <bc-message key="TRADE_IN_DIAMONDS" i18n="@@tradeInDiamonds">Trade in diamonds</bc-message>
  <bc-message key="TRADE_IN_JEWELRY" i18n="@@tradeInJewelry">Trade in jewelry (e.g. precious metals/diamonds)</bc-message>
  <bc-message key="TRADE_IN_ARMS_OR_MILITARY_GOODS" i18n="@@tradeInArmsOrMilitaryGoods">Trade in arms or other military goods</bc-message>
  <bc-message key="HOSPITALITY" i18n="@@hospitality">Hospitality</bc-message>
  <bc-message key="INTELLECTUAL_PROPERTY_PATENT_RIGHTS" i18n="@@intellectualPropertyPatentRights">Intellectual property / patent rights</bc-message>
  <bc-message key="JEWELER" i18n="@@jeweler">Jeweler</bc-message>
  <bc-message key="ART_DEALERSHIP" i18n="@@artDealership">Art dealership</bc-message>
  <bc-message key="AGRICULTURE_FORESTRY_FISHING" i18n="@@agricultureForestryFishing">Agriculture, forestry and fishing</bc-message>
  <bc-message key="OIL_GAS_INDUSTRY" i18n="@@oilGasIndustry">Oil and gas industry</bc-message>
  <bc-message key="EDUCATIONAL_SCIENTIFIC_RESEARCH" i18n="@@educationalScientificResearch">Educational/scientific research</bc-message>
  <bc-message key="GAMING_GAMBLING" i18n="@@gamingGambling">(Online) gaming and gambling</bc-message>
  <bc-message key="STORAGE_OF_GOODS" i18n="@@storageOfGoods">Storage of goods</bc-message>
  <bc-message key="GOVERNMENT_AGENCIES" i18n="@@governmentAgencies">Government agencies</bc-message>
  <bc-message key="PROFESSIONAL_SPORTS" i18n="@@professionalSports">Professional sports (players, intermediaries, representatives, (inter)national sports federations, teams/clubs and owners of teams/clubs)</bc-message>
  <bc-message key="SHIPPING_OTHER_TRANSPORT_OF_GOODS" i18n="@@shippingOtherTransportOfGoods">Shipping and other transport of goods (transport)</bc-message>
  <bc-message key="SEX_INDUSTRY" i18n="@@sexIndustry">Sex industry</bc-message>
  <bc-message key="TELECOM_IT_SERVICES" i18n="@@telecomItServices">Telecom & IT Services</bc-message>
  <bc-message key="TRUST_OFFICE" i18n="@@trustOffice">Trust Office (Corporate Service Provider)</bc-message>
  <bc-message key="AUCTION_HOUSE" i18n="@@auctionHouse">Auction house</bc-message>
  <bc-message key="WATER_SUPPLY_SEWERAGE" i18n="@@waterSupplySewerage">Water supply; sewerage; waste management and remediation activities</bc-message>
  <bc-message key="NONE_OF_THE_ABOVE_SECTORS" i18n="@@noneOfTheAboveSectors">None of the above sectors</bc-message>

  <bc-message key="WAGE_PENSION_BENEFIT" i18n="@@wagePensionBenefit">Wage / Pension / Benefit</bc-message>
  <bc-message key="INCOME_FROM_OWN_ENTERPISE" i18n="@@incomeFromOwnEnterprise">Income from own enterprise</bc-message>
  <bc-message key="STOCK_MARKET_INV_REV" i18n="@@stockMarketAndInvestmentRevenues">Stock market and investment revenues</bc-message>
  <bc-message key="INV_REAL_ESTATE_RENTAL_REV" i18n="@@investmentsRealEstateRentalRevenues">Investments in real estate / Rental revenues</bc-message>
  <bc-message key="INTEREST_REVENUES_OR_LOANS" i18n="@@interestRevenuesOnLoans">Interest revenues on loans to third parties</bc-message>

  <bc-message key="EQUITY_INHERITANCE_DONATION_SALE_COMPANY" i18n="@@equityInheritanceDonationSaleCompany">Home equity / Inheritance / Donation / Sale of own company</bc-message>
  <bc-message key="STOCK_MARKET_INV_INCOME_OWN_COMPANY" i18n="@@stockMarketAndInvestmentRevenuesIncomeOwnCompany">Stock market and investment income / Income from own company</bc-message>

  <bc-message key="SALE_OF_COMPANY" i18n="@@saleOfACompany">Sale of a company</bc-message>
  <bc-message key="INHERITANCE_DONATION" i18n="@@inheritanceDonation">Inheritance / Donation</bc-message>
  <bc-message key="LOTTERY_GAMBLING_CRYPTOCURRECNCIES" i18n="@@lotteryGamblingCryptocurrencies">Lottery / Gambling / Cryptocurrencies</bc-message>
  <bc-message key="SAVING_SALE_OWN_HOME" i18n="@@savingsSaleOwnHome">Savings / Sale own home</bc-message>

  <bc-message key="NAME" i18n="@@name">Name</bc-message>
  <bc-message key="POSITION" i18n="@@position">Position</bc-message>
  <bc-message key="DESCRIPTION" i18n="@@jobDescription">Job description</bc-message>

  <bc-message key="EMIGRATION_PENSION" i18n="@@emigrationPension">Emigration (pension)</bc-message>
  <bc-message key="EMIGRATION_WORK" i18n="@@emigrationWork">Emigration (work)</bc-message>
  <bc-message key="EXPAT" i18n="@@expat">Expat</bc-message>
  <bc-message key="NA" i18n="@@notApplicable">Not Applicable</bc-message>

  <bc-message key="FAMILY_NETHERLANDS" i18n="@@familyInTheNetherlands">Family in the Netherlands</bc-message>
  <bc-message key="LIVED_NETHERLANDS" i18n="@@livedInTheNetherlands">Lived in the Netherlands</bc-message>
  <bc-message key="WORKED_NETHERLANDS" i18n="@@workedInTheNetherlands">Worked in the Netherlands</bc-message>
  <bc-message key="OTHER_NETHERLANDS" i18n="@@other">Other</bc-message>

  <!--BUSINESS-->
  <bc-message key="GROUP_LOAN" i18n="@@groupLoan">Income from group loans</bc-message>
  <bc-message key="COMPANY_OPERATION" i18n="@@companyOperation">Revenue from the company's operational activities</bc-message>
  <bc-message key="SALE_INCOME_BUSINESS" i18n="@@dividentsOrSaleOfParticipatingInterest">Income from the sale of participations or dividends</bc-message>
  <bc-message key="LOAN_INTEREST" i18n="@@loanInterest">Revenue from interest on loans</bc-message>

  <bc-message key="REAL_ESTATE_INCOME" i18n="@@realEstateIncome">Income from (commercial) real estate</bc-message>
  <bc-message key="ACTIVITIES_REVENUE" i18n="@@revenue">Revenue from operating activities</bc-message>
  <bc-message key="PENSION_GOLDEN_HANDSHAKE" i18n="@@accruedPensionOrGoldenHandshake">Accrued pension and/or golden handshake</bc-message>
  <bc-message key="SALE_COMPANY_PARTICIPATIONS" i18n="@@saleOfCompanyOrParticipations">Sale of company and/or participations</bc-message>
  <bc-message key="GAIN_ON_INVESTMENT" i18n="@@gainOnInvestment">Gains on investment/investments</bc-message>

  <bc-message key="AFRICA" i18n="@@africa">Africa</bc-message>
  <bc-message key="ASIA" i18n="@@asia">Asia</bc-message>
  <bc-message key="EU_AND_EEA" i18n="@@euAndEea">EU + EEA</bc-message>
  <bc-message key="OCEANIA" i18n="@@oceania">Oceania</bc-message>
  <bc-message key="REST_OF_EUROPE" i18n="@@restOfEurope">Rest Europe (including Russia)</bc-message>
  <bc-message key="CANADA_AND_USA" i18n="@@canadaAndUSA">USA + Canada</bc-message>
  <bc-message key="MEXICO_SOUTH_AMERICA" i18n="@@mexicoAndSouthAmerica">South America + Mexico</bc-message>

  <bc-message key="ANGLO_TRUST" i18n="@@angloSaxonTrust">Anglo-Saxon trust</bc-message>
  <bc-message key="BEARER_SHARES" i18n="@@bearerShares">Bearer Shares</bc-message>
  <bc-message key="TRUST_OFFICE" i18n="@@corpTrustOffice">Corporate Service Provider (trust office)</bc-message>
  <bc-message key="NOMINEE_SHARE" i18n="@@nomineeShareholder">Nominee Shareholder</bc-message>
  <bc-message key="NONE_APPLICABLE" i18n="@@noneOfTheAboveApplicable">None of the above is applicable</bc-message>
</bc-catch-messages>
