// 3rd party libs
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { ServiceBase } from '@app/core/services';

import { ProductDetailQuery } from './queries/product';
import { Product } from '@app/shared/models/products';

// environment
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends ServiceBase {
  clientName: string = environment.GraphQlEndpoints.PartiesEndpoint.Name;
  productsEndpoint = environment.GraphQlEndpoints.ProductsEndpoint.Name;

  constructor(apollo: Apollo, toastr: ToastrService) {
    super(apollo, toastr);
  }


  getProduct(id: string): Observable<Product> {
    this.clientName = this.productsEndpoint;

    return this.query(new ProductDetailQuery(id), {
      fetchPolicy: 'network-only'
    });
  }
}
