import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UsersGatewayService } from '@blancoservices/bc-auth-web';


export interface Name {
  full: string;
  family: string;
  given: string;
  middle?: string;
}

export interface User {
  username: string;
  email: string;
  emailVerified: boolean;
  name: Name;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  partyId: string;
  tenantId: string;
  roles: string;
  sub: string;
  mfaMethod?: string;
}

const DEFAULT_USER = {
  username: '',
  email: '',
  emailVerified: false,
  name: {
    full: '',
    given: '',
    family: '',
    middle: ''
  },
  phoneNumber: '',
  phoneNumberVerified: false,
  partyId: '',
  roles: '',
  sub: '',
  tenantId: '',
};

@Injectable({
  providedIn: 'root'
})
export class UserService  {
  userDetails$: BehaviorSubject<User> = new BehaviorSubject(DEFAULT_USER);

  constructor(private usersGateway: UsersGatewayService) {}

  get user(): User {
    return this.userDetails$.value;
  }

  get tenantId(): string {
    return this.user.tenantId;
  }

  resolve(): Promise<User> {
    if (Object.keys(this.userDetails$.value).length && this.user.username) return Promise.resolve(this.user);

    return this.updateUser()
      .then((user: User) => {
        this.userDetails$.next(user);

        return user;
      })
      .catch((error) => {
        console.error(error);

        return Promise.reject({});
      });
  }

  updateTenantId(tenantId: string) {
    const user = { ...this.user, tenantId };
    this.userDetails$.next(user);
  }

  updateUser(): Promise<User> {
    return this.getParameters().then((result: any) => {
      const user = {
        username: result.username || result.email,
        email: result.email,
        emailVerified: result.email_verified === true,
        name: {
          full: `${result.given_name} ${result.middle_name ? result.middle_name : ' '} ${result.family_name || ''}`,
          given: result.given_name,
          family: result.family_name,
          middle: result.middle_name || null
        },
        phoneNumber: result.phone_number,
        phoneNumberVerified: result.phone_number_verified === true,
        partyId: result.preferred_username,
        roles: result.profile,
        sub: result.sub,
        tenantId: result['custom:tenant'],
        mfaMethod: result.MFADetails?.PreferredMfaSetting
      };

      this.userDetails$.next(user);

      return user;
    });
  }

  isItMe(user: any): boolean {
    return user.username === this.userDetails$.value.username;
  }

  isUserTenantClient() {
    const user = this.userDetails$.value;

    return user && user.roles.includes('ROLE_TENANT_CLIENT');
  }

  clear() {
    this.userDetails$.next(DEFAULT_USER);
  }

  private getParameters() {
    return new Promise((resolve, reject) => {
      this.usersGateway.getCurrentUser()
        .pipe(catchError((response: HttpErrorResponse) => {
          reject('cognito user not found');

          return throwError(response);
        }))
        .subscribe((user: any) => {

        const cognitoUser: any = user;

        if (cognitoUser) {
          resolve({ ...cognitoUser, roles: [cognitoUser.profile] });
        } else {
          reject('cognito user not found');
        }
      });
    });
  }
}
