import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';

import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MFAType, TokenHelper, UsersGatewayService } from '@blancoservices/bc-auth-web';

import { LoggerService } from '@shared/services/logger.service';
import { SettingsService } from '@shared/services/settings.service';
import { UserService } from '@shared/services/user.service';
import { UserLoginService } from '@shared/services/user-login.service';

@Component({
  selector: 'bc-obligatory-mfa-setup',
  templateUrl: './obligatory-mfa-setup.component.html',
  styleUrls: ['./obligatory-mfa-setup.component.scss']
})
export class ObligatoryMfaSetupComponent implements OnInit {
  qrCode$: Observable<string>;
  confirmMFACode = new UntypedFormControl(null, Validators.compose([
    Validators.required,
    Validators.maxLength(6)
  ]));
  loadingVisible = false;

  constructor(
    public router: Router,
    private usersGatewayService: UsersGatewayService,
    private toastr: ToastrService,
    private logger: LoggerService,
    private settingsService: SettingsService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    if (!TokenHelper.isAccesTokenValid()) this.router.navigate(['login']);

    this.qrCode$ = this.getMFACodeSettings();
  }

  getMFACodeSettings(): Observable<string> {
    return this.settingsService.getTenantName(this.userService.tenantId).pipe(
      catchError((err) => {
        console.error(err);

        return of('ClientPortal');
      }),
      switchMap(businessName => this.usersGatewayService.associateSoftwareMFA().pipe(
        map(({ SecretCode }) => {
          const username = this.userService.user.username;

          return `otpauth://totp/${username}?secret=${SecretCode}&digits=6&issuer=${encodeURI(businessName)}`;
        })
      ))
    );
  }

  submit() {
    if (this.confirmMFACode.invalid) return;

    this.loadingVisible = true;

    const userCode = this.confirmMFACode.value;

    this.usersGatewayService.verifySoftwareMFA(userCode).pipe(
      catchError((response: HttpErrorResponse) => {
        const errorType = response.error.errorType;
        const message = response.error.errorMessage;

        switch (errorType) {
          case 'EnableSoftwareTokenMFAException':
            this.confirmMFACode.setErrors({ invalidConfirmCode: true });
            break;
          default:
            this.logger.showError(message);
        }

        this.loadingVisible = false;

        return EMPTY;
      }),
      switchMap(() => this.usersGatewayService.setMFAPreference(MFAType.SOFTWARE)),
      switchMap(() => from(this.userService.updateUser()))
    ).subscribe(() => {
      this.toastr.success($localize `:@@success:Success!`);
      this.loadingVisible = false;
      this.router.navigate([UserLoginService.redirectUser()]);
    });
  }
}
